export const RatePlanTab = () => import('../../components/properties/partials/RatePlanTab.vue' /* webpackChunkName: "components/rate-plan-tab" */).then(c => wrapFunctional(c.default || c))
export const BasePropertiesGridItem = () => import('../../components/properties/BasePropertiesGridItem.vue' /* webpackChunkName: "components/base-properties-grid-item" */).then(c => wrapFunctional(c.default || c))
export const BasePropertiesListItem = () => import('../../components/properties/BasePropertiesListItem.vue' /* webpackChunkName: "components/base-properties-list-item" */).then(c => wrapFunctional(c.default || c))
export const FloatingCart = () => import('../../components/properties/FloatingCart.vue' /* webpackChunkName: "components/floating-cart" */).then(c => wrapFunctional(c.default || c))
export const MapModule = () => import('../../components/properties/MapModule.vue' /* webpackChunkName: "components/map-module" */).then(c => wrapFunctional(c.default || c))
export const ModalFilters = () => import('../../components/properties/ModalFilters.vue' /* webpackChunkName: "components/modal-filters" */).then(c => wrapFunctional(c.default || c))
export const ModalPriceRange = () => import('../../components/properties/ModalPriceRange.vue' /* webpackChunkName: "components/modal-price-range" */).then(c => wrapFunctional(c.default || c))
export const ModalRoomTypes = () => import('../../components/properties/ModalRoomTypes.vue' /* webpackChunkName: "components/modal-room-types" */).then(c => wrapFunctional(c.default || c))
export const PropertiesGridItem = () => import('../../components/properties/PropertiesGridItem.vue' /* webpackChunkName: "components/properties-grid-item" */).then(c => wrapFunctional(c.default || c))
export const PropertiesListHeader = () => import('../../components/properties/PropertiesListHeader.vue' /* webpackChunkName: "components/properties-list-header" */).then(c => wrapFunctional(c.default || c))
export const PropertiesListItem = () => import('../../components/properties/PropertiesListItem.vue' /* webpackChunkName: "components/properties-list-item" */).then(c => wrapFunctional(c.default || c))
export const PropertyPolicyBlock = () => import('../../components/properties/PropertyPolicyBlock.vue' /* webpackChunkName: "components/property-policy-block" */).then(c => wrapFunctional(c.default || c))
export const PropertyUnitCategory = () => import('../../components/properties/PropertyUnitCategory.vue' /* webpackChunkName: "components/property-unit-category" */).then(c => wrapFunctional(c.default || c))
export const PropertyUnitCategoryCombined = () => import('../../components/properties/PropertyUnitCategoryCombined.vue' /* webpackChunkName: "components/property-unit-category-combined" */).then(c => wrapFunctional(c.default || c))
export const PropertyUnitCategorySimple = () => import('../../components/properties/PropertyUnitCategorySimple.vue' /* webpackChunkName: "components/property-unit-category-simple" */).then(c => wrapFunctional(c.default || c))
export const PropertyUnitCategorySuggestion = () => import('../../components/properties/PropertyUnitCategorySuggestion.vue' /* webpackChunkName: "components/property-unit-category-suggestion" */).then(c => wrapFunctional(c.default || c))
export const PropertyListWithUnitCategory = () => import('../../components/properties/property-list-page/PropertyListWithUnitCategory.vue' /* webpackChunkName: "components/property-list-with-unit-category" */).then(c => wrapFunctional(c.default || c))
export const PropertyListPageSimplePropertyList = () => import('../../components/properties/property-list-page/SimplePropertyList.vue' /* webpackChunkName: "components/property-list-page-simple-property-list" */).then(c => wrapFunctional(c.default || c))
export const PropertyListPageUnitCategoryInList = () => import('../../components/properties/property-list-page/UnitCategoryInList.vue' /* webpackChunkName: "components/property-list-page-unit-category-in-list" */).then(c => wrapFunctional(c.default || c))
export const FilterCheckbox = () => import('../../components/filters/FilterCheckbox.vue' /* webpackChunkName: "components/filter-checkbox" */).then(c => wrapFunctional(c.default || c))
export const FilterSelect = () => import('../../components/filters/FilterSelect.vue' /* webpackChunkName: "components/filter-select" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterModal = () => import('../../components/filters/SearchFilterModal.vue' /* webpackChunkName: "components/search-filter-modal" */).then(c => wrapFunctional(c.default || c))
export const Blocks = () => import('../../components/global/Blocks.vue' /* webpackChunkName: "components/blocks" */).then(c => wrapFunctional(c.default || c))
export const Lmap = () => import('../../components/global/Lmap.vue' /* webpackChunkName: "components/lmap" */).then(c => wrapFunctional(c.default || c))
export const RefundableSlider = () => import('../../components/global/RefundableSlider.vue' /* webpackChunkName: "components/refundable-slider" */).then(c => wrapFunctional(c.default || c))
export const SearchBlock = () => import('../../components/global/SearchBlock.vue' /* webpackChunkName: "components/search-block" */).then(c => wrapFunctional(c.default || c))
export const Style = () => import('../../components/global/Style.vue' /* webpackChunkName: "components/style" */).then(c => wrapFunctional(c.default || c))
export const TimeAnalogClock = () => import('../../components/global/TimeAnalogClock.vue' /* webpackChunkName: "components/time-analog-clock" */).then(c => wrapFunctional(c.default || c))
export const TimeSlider = () => import('../../components/global/TimeSlider.vue' /* webpackChunkName: "components/time-slider" */).then(c => wrapFunctional(c.default || c))
export const TopNavBar = () => import('../../components/global/TopNavBar.vue' /* webpackChunkName: "components/top-nav-bar" */).then(c => wrapFunctional(c.default || c))
export const VFooter = () => import('../../components/global/VFooter.vue' /* webpackChunkName: "components/v-footer" */).then(c => wrapFunctional(c.default || c))
export const VHeader = () => import('../../components/global/VHeader.vue' /* webpackChunkName: "components/v-header" */).then(c => wrapFunctional(c.default || c))
export const DinamicBlocksGreview = () => import('../../components/global/DinamicBlocks/Greview.vue' /* webpackChunkName: "components/dinamic-blocks-greview" */).then(c => wrapFunctional(c.default || c))
export const NavbarCenter = () => import('../../components/global/navbar/Center.vue' /* webpackChunkName: "components/navbar-center" */).then(c => wrapFunctional(c.default || c))
export const SkeletonsCardSkeleton = () => import('../../components/global/skeletons/CardSkeleton.vue' /* webpackChunkName: "components/skeletons-card-skeleton" */).then(c => wrapFunctional(c.default || c))
export const SkeletonsCartSkeleton = () => import('../../components/global/skeletons/CartSkeleton.vue' /* webpackChunkName: "components/skeletons-cart-skeleton" */).then(c => wrapFunctional(c.default || c))
export const SkeletonsPropertySkeleton = () => import('../../components/global/skeletons/PropertySkeleton.vue' /* webpackChunkName: "components/skeletons-property-skeleton" */).then(c => wrapFunctional(c.default || c))
export const SkeletonsStepsSkeleton = () => import('../../components/global/skeletons/StepsSkeleton.vue' /* webpackChunkName: "components/skeletons-steps-skeleton" */).then(c => wrapFunctional(c.default || c))
export const FormsCityTaxForm = () => import('../../components/guests/forms/CityTaxForm.vue' /* webpackChunkName: "components/forms-city-tax-form" */).then(c => wrapFunctional(c.default || c))
export const FormsCreationForm = () => import('../../components/guests/forms/CreationForm.vue' /* webpackChunkName: "components/forms-creation-form" */).then(c => wrapFunctional(c.default || c))
export const FormsEditingForm = () => import('../../components/guests/forms/EditingForm.vue' /* webpackChunkName: "components/forms-editing-form" */).then(c => wrapFunctional(c.default || c))
export const MixinsForm = () => import('../../components/guests/mixins/form.js' /* webpackChunkName: "components/mixins-form" */).then(c => wrapFunctional(c.default || c))
export const MixinsModal = () => import('../../components/guests/mixins/modal.js' /* webpackChunkName: "components/mixins-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsCityTaxModal = () => import('../../components/guests/modals/CityTaxModal.vue' /* webpackChunkName: "components/modals-city-tax-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsCreationModal = () => import('../../components/guests/modals/CreationModal.vue' /* webpackChunkName: "components/modals-creation-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsEditingModal = () => import('../../components/guests/modals/EditingModal.vue' /* webpackChunkName: "components/modals-editing-modal" */).then(c => wrapFunctional(c.default || c))
export const FormsPartialsQrScanner = () => import('../../components/guests/forms/partials/QrScanner.vue' /* webpackChunkName: "components/forms-partials-qr-scanner" */).then(c => wrapFunctional(c.default || c))
export const FormsPartialsSelfie = () => import('../../components/guests/forms/partials/Selfie.vue' /* webpackChunkName: "components/forms-partials-selfie" */).then(c => wrapFunctional(c.default || c))
export const FormsPartialsSelfieAnimated = () => import('../../components/guests/forms/partials/SelfieAnimated.vue' /* webpackChunkName: "components/forms-partials-selfie-animated" */).then(c => wrapFunctional(c.default || c))
export const Combination = () => import('../../components/quotes/Combination.vue' /* webpackChunkName: "components/combination" */).then(c => wrapFunctional(c.default || c))
export const PromotionItem = () => import('../../components/promotions/PromotionItem.vue' /* webpackChunkName: "components/promotion-item" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
