import Vue from 'vue'
import VueAnalytics from 'vue-analytics'

export default async (ctx, inject) => {
  const runtimeConfig = ctx.$config && ctx.$config.googleAnalytics || {}
  const moduleOptions = {"dev":true,"debug":{"sendHitTask":true},"asyncID":async (context) => {
      const googleAnalyticsId = context.store.state['booking-networks'].getBookingNetworksRequest.data.google_analytics_id;
      const googleTagManagerId = context.store.state['booking-networks'].getBookingNetworksRequest.data.google_tag_manager_id;

      if (googleTagManagerId) {
        context.$gtm.init(googleTagManagerId);
      }

      return googleAnalyticsId;
    }}
  const options = {...moduleOptions, ...runtimeConfig}

  if (typeof options.asyncID === 'function') {
    options.id = await options.asyncID(ctx)
  }

  Vue.use(VueAnalytics, {...{ router: ctx.app.router }, ...options})

  ctx.$ga = Vue.$ga
  inject('ga', Vue.$ga)
}
